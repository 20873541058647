import { NavLink } from 'react-router-dom'

function Project({ title, pictures }) {
  return (
    <NavLink
      className="project-thumbnail"
      key={`${title}-link`}
      to={`/project/${title}`}
    >
      <img src={`/assets/pictures/${pictures[0]}`} alt="Project Thumbnail" />
      {title}
    </NavLink>
  )
}

export default Project

import IconLoader from './IconLoader'

function Stars({ handleSpaceEvent }) {
  const spaceEvent = (e) => {
    e.currentTarget.blur()
    handleSpaceEvent()
  }

  return (
    <div className="space-part">
      <div className="description">
        <IconLoader
          iconname="arrow-bottom"
          alt="Arrow pointing to spacebar"
          id="arrow-space-bar"
        />
        <p>Space!</p>
      </div>
      <button onClick={(e) => spaceEvent(e)}>
        <IconLoader
          iconname="space-bar"
          alt="Icon resembling the key spacebar"
          id="space-bar"
        />
      </button>
    </div>
  )
}

export default Stars

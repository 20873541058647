function IconLoader({ iconname, alt, id }) {
  return (
    <img
      src={`/assets/icons/${iconname}.svg`}
      alt={alt}
      id={id}
      className="icon"
    />
  )
}

export default IconLoader

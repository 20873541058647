// intial code and idea from https://codepen.io/Danielgroen/pen/VeRPOq
// adjusted by me: added stopTypeWriter and refactored as well as used p tags instead of text

let timeoutId = [];

const startTypeWriter = () => {
  // delete old ids
  timeoutId = [];
  // get text from element
  let textElements = document
    .getElementsByClassName("typewriter-js")[0]
    .getElementsByTagName("p");
  let textArray = [];
  [].forEach.call(textElements, function (el) {
    textArray.push(el.innerHTML);
  });
  // start the text animation
  timeoutId.push(setTimeout(() => startTextAnimation(textArray, 0), 800));
};

// stop all timeOut Functions to avoid generating errormessages
const stopTypeWriter = () => {
  for (let i = 0; i < timeoutId.length; i++) {
    clearTimeout(timeoutId[i]);
  }
};

// type one text in the typwriter
// keeps calling itself until the text is finished
function typeWriter(text, i, fnCallback) {
  // chekc if text isn't finished yet
  if (i < text.length) {
    // add next character to h1
    document.getElementsByClassName("typewriter-js")[0].innerHTML =
      text.substring(0, i + 1) +
      '<span aria-hidden="true" className="cursor"></span>';

    // wait for a while and call this function again for next character
    timeoutId.push(
      setTimeout(function () {
        typeWriter(text, i + 1, fnCallback);
      }, 200)
    );
  }
  // text finished, call callback if there is a callback function
  else if (typeof fnCallback == "function") {
    // call callback after timeout
    timeoutId.push(setTimeout(fnCallback, 600));
  }
}

// start a typewriter animation for a text in the dataText array
function startTextAnimation(text, i) {
  if (typeof text[i] == "undefined") {
    timeoutId.push(
      setTimeout(function () {
        startTextAnimation(0);
      }, 20000)
    );
  }
  // check if dataText[i] exists
  if (i < text.length) {
    // text exists! start typewriter animation
    typeWriter(text[i], 0, function () {
      // after callback (and whole text has been animated), start next text
      startTextAnimation(text, i + 1);
    });
  }
}

const typeWriterFunctions = { startTypeWriter, stopTypeWriter };

export default typeWriterFunctions;

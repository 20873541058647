function Contact() {
  return (
    <div className="contact">
      <p>Contact me in german or english via mail or my social media below:)</p>
      <br />
      <p>
        E-Mail: <a href="mailto:leajell@aol.com">leajell@aol.com</a>
      </p>
      <br />
      <h3>Impressum:</h3>
      <p>Lea Jell</p>
      <p>Ortlerstraße 82</p>
      <p>86163 Augsburg</p>
      <br />
      <p>
        Github:{" "}
        <a href="https://github.com/leje512/">https://github.com/leje512/</a>
      </p>
      <p>
        LinkedIn:{" "}
        <a href="https://www.linkedin.com/in/lea-jell-3a3b681b5/">
          https://www.linkedin.com/in/lea-jell-3a3b681b5/
        </a>
      </p>
      <p>
        Xing:{" "}
        <a href="https://www.xing.com/profile/Lea_Jell">
          https://www.xing.com/profile/Lea_Jell
        </a>
      </p>
    </div>
  );
}

export default Contact;

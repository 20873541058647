import { useParams } from 'react-router-dom'
import { projectData } from '../../assets/projectData.js'

function ProjectInfos() {
  let { title } = useParams()
  let project = projectData.find((data) => data.title === title)

  return (
    <div className="project">
      <h1>{project.title}</h1>
      <p>{project.shortdesc}</p>
      <div class="details">
       <p><span class="strong">Contributors:</span> {project.contributors}</p>
        <p><span class="strong">Techstack:</span> {project.techstack}</p>
        {project.url !== undefined && (
          <p>
            <span class="strong">URL:{' '}</span>
            <a href={project.url} target="_blank" rel="noopener noreferrer">
              {project.url}
            </a>
          </p>
        )}
        <p><span class="strong">Git:{' '}</span>
          <a href={project.git} target="_blank" rel="noopener noreferrer">
            {project.git}
          </a>
         </p>
      </div>
      <div className="gallery">
        {project.pictures.map((pic) => (
          <img
            key={pic}
            src={`/assets/pictures/${pic}`}
            alt="Project Thumbnail"
          />
        ))}
      </div>
    </div>
  )
}

export default ProjectInfos

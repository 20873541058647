import Project from './Project'
import { projectData } from '../../assets/projectData.js'

function Projects() {
  return (
    <div className="projects">
      {projectData.map((project) => (
        <Project
          key={project.title}
          title={project.title}
          shortdesc={project.shortdesc}
          contributors={project.contributors}
          techstack={project.techstack}
          url={project.url}
          git={project.git}
          pictures={project.pictures}
        />
      ))}
    </div>
  )
}

export default Projects

export const projectData = [
  {
    title: "Learn to Code",
    shortdesc:
      "Bachelorproject - Interactive App for programming beginners to learn coding. An automatic tutor gives tips and hints to complete the tasks and the solution is checked with unit tests.",
    contributors:
      "Lea Jell",
    techstack: "Svelte, Typescript, CodeMirror 6, acorn.js",
    git: "https://github.com/leje512/learn-to-code",
    url: "https://leajell.de/ba/",
    pictures: ["learn_to_code_screenshot_annotated.jpg", "learn_to_code_screenshot_tutor.png"],
  },
  {
    title: "FLAI",
    shortdesc:
      "Semesterproject to learn german sign language with AI feedback.",
    contributors:
      "Stefanie Jany, Lea Jell, Martin Kohnle, Anja Krendlinger, Moritz Kronberger, Eva Kunzmann, Kieu Pham",
    techstack: "Vue, Typescript, Express, SCSS, PostgreSQL, Keras",
    git: "https://github.com/MoritzKronberger/FLAI",
    pictures: ["flai.png", "flai-2.png", "flai-3.png"],
  },
  {
    title: "Snippets",
    shortdesc:
      "Courseproject: A SNS based on Twitter and Stackoverflow, where you can post, like and comment code snippets.",
    contributors: "Lea Jell, Martin Kohnle, Moritz Kronberger",
    techstack: "Vue, Express, PostgreSQL",
    git: "https://github.com/leje512/snippets",
    pictures: ["snippets.png", "snippets-2.png", "snippets-3.png"],
  },
  {
    title: "Don't laugh",
    shortdesc:
      "Courseproject: Detects your facial expression via camera. If you laugh, you lose.",
    contributors: "Lea Jell",
    techstack: "JavaScript, Face-Api, P5.js",
    url: "https://hs-augsburg.de/homes/jelllea/dont-laugh/",
    git: "https://github.com/leje512/dont-laugh",
    pictures: ["dont-laugh.png", "dont-laugh-2.png", "dont-laugh-3.png"],
  },
  {
    title: "Simon Says",
    shortdesc:
      "Courseproject: A two-player minigame of the game Simon Says, utilizing a Arduino Robot.",
    contributors: "Lea Jell, Lena Krause",
    techstack: "JavaScript, P5.js, Arduino, Socket.IO",
    url: "https://ancient-oasis-54418.herokuapp.com/",
    git: "https://github.com/leje512/block-3-simon-says-mirror/",
    pictures: ["simon-says.png", "simon-says-2.png", "simon-says-3.png"],
  },
];

import { Component } from 'react'
import typeWriterFunctions from '../../assets/js/typewriter.js'

class Homepage extends Component {
  componentDidMount() {
    typeWriterFunctions.startTypeWriter()
  }
  componentWillUnmount() {
    typeWriterFunctions.stopTypeWriter()
  }
  render() {
    return (
      <div className="homepage">
        <h1 className="typewriter-js">
          <p className="hidden">Hello, I'm Lea,</p>
          <span aria-hidden="true" className="cursor"></span>
        </h1>
        <h2>a Javascript Web Developer.</h2>
        <p>
          I'm currently working as a fullstack webdeveloper at a software
          agency.
        </p>
      </div>
    )
  }
}

export default Homepage

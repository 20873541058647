import '../assets/scss/main.scss'
import React, { useState, useEffect } from 'react'
import Contact from './Contact/Contact'
import Homepage from './Home/Homepage'
import Navigation from './Home/Navigation'
import Projects from './Projects/Projects'
import ProjectInfos from './Projects/ProjectInfos'
import Stars from '../components/Stars'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  const [spaceActive, setSpaceActive] = useState(false)

  useEffect(() => {
    document.addEventListener('keyup', callSpaceEvent)

    return () => document.removeEventListener('keyup', callSpaceEvent)
  })
  const callSpaceEvent = (event) => {
    if (event.code === 'Space') {
      handleSpaceEvent()
    }
  }
  const handleSpaceEvent = (event) => {
    setSpaceActive(!spaceActive)
  }

  return (
    <BrowserRouter>
      <div className={`app ${spaceActive ? 'space-background' : 'normal'}`}>
        <Navigation />
        <div id="stars" className={spaceActive ? '' : 'hidden'}>
          <div id="stars1"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
        </div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project/:title" element={<ProjectInfos />} />
        </Routes>
        <Stars handleSpaceEvent={handleSpaceEvent} />
      </div>
    </BrowserRouter>
  )
}

export default App

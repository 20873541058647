import IconLoader from './IconLoader'
import { createBrowserHistory } from 'history'

function BackButton() {
  let history = createBrowserHistory()
  document.addEventListener('keyup', function (event) {
    if (event.key === 'Backspace') {
      goBack()
    }
  })
  const goBack = (e) => {
    if (e) e.currentTarget.blur()
    history.back()
  }

  return (
    <button onClick={(e) => goBack(e)}>
      <IconLoader
        iconname="back-button"
        alt="Icon resembling the key backspace"
        id="back-button"
      />
    </button>
  )
}

export default BackButton

import { NavLink } from 'react-router-dom'
import Back from '../../components/Back'

function Navigation() {
  return (
    <nav>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/projects">Projects</NavLink>
      <NavLink to="/contact">Contact</NavLink>
      <Back />
    </nav>
  )
}

export default Navigation
